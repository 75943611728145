import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { TagQueryProps } from 'src/types';
import {
	ArticleSidebarList,
	ArticleSidebarHeadline,
	ArticleSidebarLink,
} from './ArticleSidebar.dump';

const ArticleSidebarTags: React.FC = () => {
	const data = useStaticQuery(graphql`
		query TagsQuery {
			allTagsYaml(sort: { fields: fields___name }) {
				edges {
					node {
						id
						fields {
							name
							slug
						}
					}
				}
			}
		}
	`);
	const tags: TagQueryProps[] = data.allTagsYaml.edges.map(el => el.node);

	return (
		<ArticleSidebarList>
			<ArticleSidebarHeadline>Posts by tags</ArticleSidebarHeadline>
			{tags.map(el => (
				<li key={el.id}>
					<ArticleSidebarLink to={el.fields.slug}>{el.fields.name}</ArticleSidebarLink>
				</li>
			))}
		</ArticleSidebarList>
	);
};

export default ArticleSidebarTags;
