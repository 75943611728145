import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
	ArticleSidebarList,
	ArticleSidebarHeadline,
	ArticleSidebarLink,
} from './ArticleSidebar.dump';

interface ResentPostQuery {
	id: string;
	fields: {
		slug: string;
	};
	frontmatter: {
		h1: string;
	};
}

const ArticleSidebarRecent: React.FC = () => {
	const data = useStaticQuery(graphql`
		query RecentQuery {
			allMdx(
				filter: { fileAbsolutePath: { regex: "//blog//" } }
				sort: { fields: fields___date, order: DESC }
				limit: 3
			) {
				edges {
					node {
						id
						fields {
							slug
						}
						frontmatter {
							h1
						}
					}
				}
			}
		}
	`);
	const posts: ResentPostQuery[] = data.allMdx.edges.map(el => el.node);

	return (
		<ArticleSidebarList>
			<ArticleSidebarHeadline>Recent posts</ArticleSidebarHeadline>
			{posts.map(el => (
				<li key={el.id}>
					<ArticleSidebarLink to={el.fields.slug}>{el.frontmatter.h1}</ArticleSidebarLink>
				</li>
			))}
		</ArticleSidebarList>
	);
};

export default ArticleSidebarRecent;
