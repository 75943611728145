import React from 'react';
import { graphql } from 'gatsby';
import { BlogQueryProps, TagQueryProps } from 'src/types';
import BlogLayout from 'src/layout/BlogLayout';

export interface BlogPostTemplateProps {
	data: {
		tagsYaml: TagQueryProps;
		allMdx: {
			edges: {
				node: BlogQueryProps;
			}[];
		};
	};
}

const BlogPostTemplate: React.FC<BlogPostTemplateProps> = ({ data }) => {
	return <BlogLayout data={data.allMdx.edges.map(el => el.node)} seoPage={data.tagsYaml} />;
};

export const pageQuery = graphql`
	query TagQuery($id: String, $tag: String) {
		tagsYaml(id: { eq: $id }) {
			id
			fields {
				name
				slug
			}
			tab_title
			meta_description
			meta_keywords
		}
		allMdx(
			filter: { fileAbsolutePath: { regex: "//blog//" }, frontmatter: { tags: { eq: $tag } } }
			sort: { fields: fields___date, order: DESC }
		) {
			edges {
				node {
					id
					frontmatter {
						meta_description
						author
						banner
						h1
					}
					fields {
						date
						slug
					}
				}
			}
		}
	}
`;

export default BlogPostTemplate;
