import React, { useEffect, useState } from 'react';
import Layout from 'src/layout/Layout';
import { BlogQueryProps, SeoPageProps } from 'src/types';
import { OldRedButton } from 'src/components/buttons/Button.dump';
import { ArticleList, ArticleActions } from 'src/components/BlogComponents/ArticleItem.dump';
import ArticleItem from 'src/components/BlogComponents/ArticleItem';
import ArticleSidebar from 'src/components/BlogComponents/ArticleSidebar';
import { ArticleSidebarReference } from 'src/components/BlogComponents/ArticleSidebar.dump';
import ArticleSidebarTags from 'src/components/BlogComponents/ArticleSidebarTags';
import ArticleSidebarRecent from 'src/components/BlogComponents/ArticleSidebarRecent';
import Article from 'src/components/BlogComponents/Article';

export interface BlogProps {
	data: BlogQueryProps[];
	seoPage: SeoPageProps;
}

const BlogLayout: React.FC<BlogProps> = ({ data, seoPage }) => {
	const STEP = 5,
		LENGTH = data.length;
	const [limit, setLimit] = useState(STEP);
	const [visiblePosts, setVisiblePosts] = useState<BlogQueryProps[]>();

	const loadMore = () => {
		setLimit(limit + STEP);
	};

	useEffect(() => {
		setVisiblePosts(data.slice(0, limit));
	}, [limit]);

	return (
		<Layout seoPage={seoPage} theme="white" css={'background: #f8fafd;'}>
			<Article>
				<ArticleList>
					{visiblePosts &&
						visiblePosts.map(mdx => {
							return <ArticleItem key={mdx.id} {...mdx} />;
						})}
				</ArticleList>
				<ArticleSidebar>
					<ArticleSidebarReference>
						<ArticleSidebarRecent />
						<ArticleSidebarTags />
					</ArticleSidebarReference>
				</ArticleSidebar>
				{limit <= LENGTH && (
					<ArticleActions>
						<OldRedButton onClick={loadMore}>Load more</OldRedButton>
					</ArticleActions>
				)}
			</Article>
		</Layout>
	);
};

export default BlogLayout;
