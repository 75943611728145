import React from 'react';
import { BlogQueryProps } from 'src/types';
import {
	ArticleItemContainer,
	ArticleItemImg,
	ArticleItemContent,
	ArticleItemInfo,
	ArticleItemTime,
} from './ArticleItem.dump';
import { ReactComponent as TimeIcon } from '../../styles/assets/images/ui/icons/icon-clock-grey.svg';
import { getPostInfo, getPostTimePass } from './utils';

const ArticleItem: React.FC<BlogQueryProps> = ({ fields, frontmatter }) => {
	return (
		<ArticleItemContainer to={fields.slug}>
			<ArticleItemImg banner={frontmatter.banner} />
			<ArticleItemContent>
				<h2>{frontmatter.h1}</h2>
				<p>{frontmatter.meta_description}</p>
				<ArticleItemInfo>
					<div>{getPostInfo({ fields, frontmatter })}</div>
					<ArticleItemTime>
						{getPostTimePass(fields.date)}
						<TimeIcon />
					</ArticleItemTime>
				</ArticleItemInfo>
			</ArticleItemContent>
		</ArticleItemContainer>
	);
};

export default ArticleItem;
